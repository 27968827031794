<template>
  <div>
    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i2/O1CN01DpfodD1vmyot2f7jz_!!6000000006216-0-tps-1920-9035.jpg"
          alt=""
        />        
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightshow: true,
      phoneshow: false,
      a: "visible",
    };
  },
  methods: {
    changeStyle() {
      if (this.a === "visible") {
        this.a = "hidden";
      } else {
        this.a = "visible";
      }
    },
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    
  },
};
</script>

<style scoped>


#buy {
  position: absolute;
  top: 0;
}

.main1 {
  text-align: center;
  padding-top: 50px;
  position: relative;
}

section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;

  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}

header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
</style>
